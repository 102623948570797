<template>
   <a-modal width='700px'
            :confirmLoading='confirmLoading' 
            :centered='true' 
            :maskClosable='false'
            v-model="show" 
            :title='modalTitle' 
            okText='保存' 
            @ok='save'>
      <div class="message-form">
         <a-form-model ref="messageForm" layout='vertical' :model="form" :rules="rules" >
            <a-form-model-item label="消息备注" prop='remark'>
               <a-input v-model="form.remark" :maxLength='30' allowClear />
            </a-form-model-item>
            <a-form-model-item label="消息内容" prop='content'>
               <a-input v-model="form.content" :maxLength='50' allowClear />
            </a-form-model-item>

            <!-- 活动通知才显示 -->
            <a-form-model-item v-if="formType === 'activity' " label="活动时间" prop='activityTime'>
               <a-range-picker :placeholder='rangePickerPlaceholder' v-model="form.activityTime" :show-time="showTime" />
            </a-form-model-item>


            <a-form-model-item style="min-height: 250px" prop='img'>
               <span class="custom-label" slot="label">
                  <span>消息图片</span>
                  <span>建议上传 630 x 268 的图片</span>
               </span>
               <a-upload
                  action=""
                  :beforeUpload='beforeUpload'
                  list-type="picture-card"
                  :remove='handleRemove'
                  @preview="previewImg"
                  :showUploadList='false'
                  :disabled='confirmLoading'
                  >
                  <div v-if="uploadBtnShow" class="upload-btn">
                     <a-icon type='plus' />
                     <div class="ant-upload-text">上传图片</div>
                  </div>
               </a-upload>
               <div class="img-wrapper" v-if='!uploadBtnShow'>
                  <img :src="form.img" alt="">
                  <div class="tool">
                     <a-icon @click="previewImg" type="eye" title="查看" />
                     <a-icon @click="handleRemove" type="delete" title="删除" />
                  </div>
               </div>
            </a-form-model-item>
            <a-form-model-item label="消息跳转链接" prop='link'>
               <a-input v-model="form.link" type='textarea' :maxLength='300' :rows="4" allowClear />
            </a-form-model-item>
            <a-form-model-item label="消息可见用户">
               <a-radio-group v-model="form.userDisplayType">
                  <a-radio :value="0">全部用户</a-radio>
                  <a-radio :value="1">仅推广者</a-radio>
                  <a-radio :value="2">仅消费者</a-radio>
               </a-radio-group>
            </a-form-model-item>

            <!-- 系统消息才显示 -->
            <a-form-model-item label="是否立即发送" v-if="formType === 'system'">
               <a-radio-group v-model="form.status">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
               </a-radio-group>
            </a-form-model-item>

            <!-- 活动通知才显示 -->
            <a-form-model-item v-if="formType === 'activity'" label="消息可见时间" prop='displayTime'>
               <a-range-picker :placeholder='rangePickerPlaceholder' v-model="form.displayTime" :show-time="showTime" />
            </a-form-model-item>
         </a-form-model>
      </div>
   </a-modal>

</template>

<script>
import moment from 'moment'
import api from '@/api'
export default {
   name: 'MessageForm',
   props: {
      value: {
         type: Boolean,
         default: false
      },
      loading: {
         type: Boolean,
         default: false
      },
      messageInfo: {
         type: Object,
         default: () => ({})
      },
      formType: {
         tyoe: String,
         default: 'system',
         validator (value) {
            return ['system', 'activity'].includes(value)
         }
      }
   },
   watch: {
      loading: {
         immediate: true,
         handler (val) {
            this.confirmLoading = val
         }
      },
      value: {
         immediate: true,
         handler (val) {
            this.show = val
         }
      },
      messageInfo: {
         deep: true,
         immediate: true,
         handler (val) {
            if (Object.keys(val).length) {
               this.form = {
                  ...this.form,
                  ...val
               }
            } else {
               this.form = {
                  remark: undefined,
                  content: undefined,
                  img: undefined,
                  link: undefined,
                  userDisplayType: 0,
                  status: 1,
                  img: undefined,
                  activityTime: [],
                  displayTime: []
               }
               this.handleRemove()
            }
            this.$nextTick(() => {
               this.$refs.messageForm && this.$refs.messageForm.clearValidate()
            })
         }
      },
      show (val) {
         this.$emit('input', val)
      }
   },
   data () {
      let validateTime = (rule, value, callback) => {
         let endTime = value[1]
         if (endTime.isBefore()) {
            return callback(new Error('结束时间不能早于当前时间'))
         }
         return callback()
      }
      return {
         fileList: [],
         show: false,
         confirmLoading: false,
         file: null,
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('00:00:00', 'HH:mm:ss')]
         },
         rangePickerPlaceholder: ['开始时间', '结束时间'],
         form: {
            remark: undefined,
            content: undefined,
            img: undefined,
            link: undefined,
            userDisplayType: 0,
            status: 1,
            img: undefined,
            activityTime: [],
            displayTime: []
         },
         rules: {
            remark: { required: true, message: '请输入消息备注', trigger: 'change'},
            content: { required: true, message: '请输入消息内容', trigger: 'change'},
            activityTime: [
               { required: true, message: '请选择活动时间', trigger: 'change'},
               // { validator: validateTime, trigger: 'change'}
            ],
            displayTime: [
               { required: true, message: '请选择消息可见时间', trigger: 'change'},
               { validator: validateTime, trigger: 'change'}
            ],
            img: { required: this.formType === 'activity', message: '请上传图片', trigger: 'blur'},
            link: { required: this.formType === 'activity', message: '请输入跳转链接', trigger: 'change'}
         }
      }
   },
   computed: {
      uploadBtnShow () {
         return !this.form.img
      },
      modalTitle () {
         let type = {
            'activity': '活动通知',
            'system': '系统消息'
         }
         let editType = this.form.id ? '编辑' : '添加'
         return editType + type[this.formType]
      }
   },
   methods: {
      save () {
         this.$refs.messageForm.validate().then(() => {
            this.$emit('submitForm', this.form)
         }).catch((err) => {
            console.log(err)
         })
      },
      beforeUpload (file) {
         this.validateFile(file).then(() => {
            this.uploadImg()
         }).catch(err => {
            this.$message.error(err)
         })
         return false
      },
      validateFile (file) {
         return new Promise((resolve, reject) => {
            let {type, size} = file
            let imgReg = /^image/
            if (!imgReg.test(type)) {
               return reject('请选择图片上传')
            }
            if (size > 1024 * 150) {
               return reject('图片大小不能超过150kb')
            }
            this.file = file
            resolve()
         })

      },
      previewImg () {
         let url = this.form.img
         if (!url) return 
         this.$viewerApi({
            images: [url]
         })
      },
      uploadImg () {
         if (!this.file) return
         this.confirmLoading = true
         let formData = new FormData()
         formData.append('file', this.file)
         api.util.uploadFile(formData).then(res => {
            if (res.code === '00000') {
               this.form.img = res.data
               this.$message.success('上传成功')
            }
         }).finally(() => {
            this.confirmLoading = false
         })
      },
      handleRemove () {
         this.file = null
         this.form.img = ''
      }
   }
}
</script>

<style scoped lang='less'>
/deep/ .ant-modal-title {
   font-weight: bold;
   font-size: 15px;
}
.message-form {
   max-height: 70vh;
   overflow: auto;
   padding: 0 10px;
   &::-webkit-scrollbar {/*滚动条整体样式*/
     width: 8px;     /*高宽分别对应横竖滚动条的尺寸*/
     height: 5px;
   }
   &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.2);
     background: #999;
     &:hover{
       background: #535353;
     }
   }
   &::-webkit-scrollbar-track {/*滚动条里面轨道*/
     -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.2);
     border-radius: 10px;
     background: #EDEDED;
   }
   /deep/ .ant-form-item-label > label {
      font-weight: bold;
      font-size: 14px;
   }

   /deep/ .ant-upload-list-item, .upload-btn{
      width: 300px;
      height: 150px;
   }
   .upload-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      i {
         font-size: 24px;
      }
      .ant-upload-text {
         margin: 8px 0;
      }
   }
   .img-wrapper {
      height: 150px;
      max-width: 100%;
      display: inline-block;
      position: relative;
      img {
         height: 100%;
         max-width: 100%;
      }
      .tool {
         visibility: hidden;
      }
      &:hover .tool {
         visibility: inherit;
         display: flex;
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         justify-content: center;
         align-items: center;
         transition: all .3s;
         background-color: rgba(0,0,0,.65);
         i {
            color: #e6e2e0;
            font-size: 18px;
            margin: 0 12px;
            cursor: pointer;
            &:hover {
               color: #fff;
            }
         }
      }
      
   }
   .custom-label {
      & > span:nth-child(2) {
         color: #ababad;
         font-size: 12px;
         font-weight: normal;
         margin-left: 12px;
      } 
   }
}
</style>