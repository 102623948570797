<template>
  <a-card :bordered='false'>
    <a-row class="query-row" type='flex' :gutter="12">
      <a-col style="width:290px">
        <a-input allowClear placeholder='请输入' v-model="queryData.content">
          <a-select slot="addonBefore" v-model="inputType" style="width: 90px">
            <a-select-option value="remark">消息备注</a-select-option>
            <a-select-option value="msgId">消息ID</a-select-option>
          </a-select>
        </a-input>
      </a-col>
      <a-col style="width:420px">
           <span>展示开始时间：</span>
           <a-range-picker
            :placeholder='placeholder'
            :show-time="showTime"
            v-model="queryData.displayTime"
            />
        </a-col>
        <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary' style="margin-right:12px">查询</a-button>
        <a-button v-auth="'messageCenter:userMsgOperate'" icon="plus" type='primary' @click="editMessage()">添加活动通知</a-button>
    </a-row>

    <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
        :loading="loading"
        :scroll="{x: true}"
        :rowKey="record => record.id"
        size="default">
        <template slot="options" slot-scope="text, record">
          <div class="options-wrapper">
            <a v-auth="'messageCenter:userMsgOperate'" @click="editMessage(record)">编辑</a>
            <a v-auth="'messageCenter:userMsgUpdateStatus'" @click="delMsg(record.id)">删除</a>
          </div>
        </template>
    </a-table>
    <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
    />


      <MessageForm formType='activity' :messageInfo='currentRow' v-model="showMessageFormModal" :loading='confirmLoading' @submitForm="submitForm" />

  </a-card>
</template>

<script>
import MessageForm from './components/messageForm.vue'
import api from '@/api'
import moment from 'moment'
export default {
  name: 'ActivityMessage',
  components: {
    MessageForm
  },
  data () {
    return {
      inputType: 'remark',
      loading: false,
      currentRow: {},
      showMessageFormModal: false,
      confirmLoading: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      placeholder: ['起始时间', '结束时间'],
      queryData: {
        content: undefined,
        displayTime: []
      },
      showTime:{
        format: 'HH:mm:ss',
        defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('00:00:00', 'HH:mm:ss')]
      },
      columns: [
        {
          title: '序号',
          align: 'center',
          ellipsis:true,
          customRender: (_, __, index) => {
            return index + 1
          }
        },
        {
          title: '消息ID',
          dataIndex: 'id',
          align: 'center',
          ellipsis:true
        },
        {
          title: '消息备注',
          dataIndex: 'remark',
          align: 'center',
          ellipsis:true
        },
        {
          title: '可见用户',
          dataIndex: 'userDisplayTypeStr',
          align: 'center',
          ellipsis:true
        },
        {
          title: '消息内容',
          dataIndex: 'content',
          align: 'center',
          ellipsis:true,
          customRender: text => {
            return (
              <div class='content-txt'>{text}</div>
            )
          }
        },
        {
          title: '消息图片',
          dataIndex: 'img',
          align: 'center',
          ellipsis:true,
          customRender: (text, record) => {
            return (
              text ? 
              <a onClick={() => this.previewImg(text)}>查看</a>
              : '-'
            )
          }
        },
        {
          title: '跳转链接',
          dataIndex: 'link',
          align: 'center',
          ellipsis:true,
          customRender: text => {
            return (
              <div class='content-txt'>{text}</div>
            )
          }
        },
        {
          title: '活动时间',
          align: 'center',
          ellipsis:true,
          customRender: (_, {activityBeginTimeStr, activityEndTimeStr}) => {
            return `${activityBeginTimeStr} ~ ${activityEndTimeStr}`
          }
        },
        {
          title: '展示时间',
          align: 'center',
          ellipsis:true,
          customRender: (_, {displayBeginTimeStr, displayEndTimeStr}) => {
            return `${displayBeginTimeStr} ~ ${displayEndTimeStr}`
          }
        },
        {
          title: '操作',
          align: 'center',
          ellipsis:true,
          scopedSlots: { customRender: 'options' }
        },
      ],
      tableData: []
    }
  },
  created () {
    this.loadTableData()
  },
  methods: {
    loadTableData () {
      this.loading = true
      let { content, displayTime } = this.queryData
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        msgType: 2,
        [this.inputType]: content
      }
      if (displayTime[0] && displayTime[1]) {
        params.displayBeginTime = displayTime[0].unix()
        params.displayEndTime = displayTime[1].unix()
      }
      api.operationManage.userMsgList(params).then(res => {
        if (res.code === '00000') {
          this.tableData = res.data.dataList
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => this.loading = false)
    },
    previewImg (url) {
      this.$viewerApi({
        images: [url]
      })
    },
    editMessage (record) {
      if (record) {
        let {
          activityBeginTimeStr,
          activityEndTimeStr,
          displayBeginTimeStr,
          displayEndTimeStr,
        } = record
        record.activityTime = [moment(activityBeginTimeStr), moment(activityEndTimeStr)]
        record.displayTime = [moment(displayBeginTimeStr), moment(displayEndTimeStr)]
      }
      this.currentRow = {...record} || {}
      this.showMessageFormModal = true
    },
    submitForm (form) {
      this.confirmLoading = true
      let {content, link, userDisplayType, status, remark, img, id, activityTime, displayTime} = form
      let params = {
        content, link, userDisplayType, status, remark, img, id,
        msgType: 2,
        activityBeginTime: activityTime[0].unix(),
        activityEndTime: activityTime[1].unix(),
        displayBeginTime: displayTime[0].unix(),
        displayEndTime: displayTime[1].unix(),
      }
      api.operationManage.userMsgOperate(params).then(res => {
        if (res.code === '00000') {
          this.showMessageFormModal = false
          this.$message.success(res.msg)
          this.loadTableData()
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },

    delMsg (id) {
      this.$confirm({
        content:`是否确认删除该消息`,
        okText: `确认删除`,
        onOk: () => {
          return new Promise((resolve,reject) => {
            api.operationManage.userMsgUpdateStatus({id, status: -1}).then(res => {
              if (res.code === '00000') {
                this.loadTableData()
                resolve(res.msg)
              } else {
                reject(res.msg)
              }
            })
          }).then((res) => {
            this.$message.success(res)
          }).catch((err) => {
            this.$message.error(err)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang='less'>
.content-txt {
  max-width: 350px;
  min-width: 200px;
  overflow: hidden;
  white-space: pre-wrap;
}

.options-wrapper {
  > a {
    margin-right: 12px;
  }
  > a:last-child {
    margin-right: 0;
  }
}
</style>